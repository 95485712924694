import React from 'react'

import SubNav from '../components/SubNav'
import NavelImage from '../components/NavelImage'
import TemplateWrapper from '../components/Layout'
import AssembliesListing from '../components/AssembliesListing'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import { graphql } from 'gatsby'

//const renderAst = new rehypeReact({
//createElement: React.createElement,
//components: { 'donate-box': DonateBox },
//}).Compiler

export default ({ pageContext, data }) => {
  const post = data.static

  let subNavItems = data.subnav
  if (post.frontmatter.hideFromSubnav) subNavItems = { edges: [{ node: post }] }

  return (
    <TemplateWrapper>
      <div className="post-wrap static-page">
        {post.frontmatter.hideFromSubnav && (
          <div className="subnav">
            <h2>{post.frontmatter.title}</h2>
          </div>
        )}
        {!post.frontmatter.hideFromSubnav && (
          <SubNav
            items={subNavItems}
            currentItem={post.fields.slug}
            slug={post.fields.slug}
          />
        )}

        <div className="post">
          {post.frontmatter.primaryImage && (
            <NavelImage
              image={post.frontmatter.primaryImage}
              imageClass="post-primary-image"
            />
          )}
          <div className="post-content">
            {pageContext.slug === '/assemblies/events/' && (
              <div className="post-assemblies">
                <AssembliesListing
                  data={data.assemblies}
                  frontMatter={post.frontmatter}
                  pageContext={pageContext}
                />
              </div>
            )}

            <div className="post-body">
              <MDXRenderer
                gallery={post.frontmatter.galleryImages}
                data={data.assemblies}
                frontMatter={post.frontmatter}
                pageContext={pageContext}
              >
                {post.body}
              </MDXRenderer>
              {pageContext.slug === '/assemblies/propose/' && (
                <div className="post-assemblies">
                  <AssembliesListing
                    data={data.assemblies}
                    frontMatter={post.frontmatter}
                    pageContext={pageContext}
                  />
                </div>
              )}

              {pageContext.slug === '/assemblies/participate/' && (
                <div className="post-assemblies">
                  <AssembliesListing
                    data={data.assemblies}
                    frontMatter={post.frontmatter}
                    pageContext={pageContext}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </TemplateWrapper>
  )
}

export const query = graphql`
  query StaticQuery($slug: String!) {
    static: mdx(fields: { slug: { eq: $slug } }) {
      body
      id
      fields {
        slug
      }
      frontmatter {
        title
        path
        hideFromSubnav
        eventType {
          assemblies
          parent
          childOf
        }
        galleryImages {
          publicURL
          childImageSharp {
            fixed(width: 780, quality: 70) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        primaryImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    assemblies: allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { eventType: { assemblies: { ne: null } } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            customDate
            time
            eventType {
              assemblies
              parent
              childOf
            }
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
    subnav: allMdx(
      filter: {
        frontmatter: { hideFromSubnav: { ne: "true" } }
        fileAbsolutePath: { regex: "/about/|/assemblies/" }
      }
      sort: { fields: [frontmatter___subnavOrder], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`
