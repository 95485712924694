import React from 'react'
import { Link } from 'gatsby'

import primaryColor from '../../utils/primaryColor'

const path = require('path')

class SubNav extends React.Component {
  render() {
    let edges = this.props.items.edges.filter(
      (el) =>
        path.dirname(el.node.fields.slug) ===
        path.dirname(this.props.currentItem)
    )

    if (!edges) new Array(edges)

    return (
      <div>
        <ul
          className={
            'subnav' + (edges.length <= 1 ? ' subnav-single-item' : '')
          }
          style={
            {
              // background: '#fc456f'
            }
          }
        >
          <li style={{ display: 'none' }}>
            <input
              placeholder="search"
              type="text"
              style={{
                border: '2px solid black',
                padding: '5px',
                font: 'inherit',
                position: 'relative',
                top: '-6px',
                width: '58px',
                minWidth: '58px',
                transition: 'width .3s',
              }}
            />
          </li>

          {edges.length > 0 &&
            path.dirname(edges[0].node.fields.slug) === '/about' && (
              <li key="news-subnav-link" className="news-subnav-link">
                <Link to="/news" activeStyle={{ color: primaryColor() }}>
                  News
                </Link>
              </li>
            )}

          {edges.map(({ node }) => (
            <li key={node.id}>
              <Link
                to={
                  node.frontmatter.path
                    ? node.frontmatter.path
                    : node.fields.slug
                }
                activeStyle={{ color: primaryColor() }}
              >
                {node.frontmatter.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default SubNav
